import { AppSetting } from '@app/settings/shared/setting.model';
import { Component, HostBinding } from '@angular/core';
import { IAppSetting } from '@app/settings/shared/setting.interface';
import { LegacyService } from '@app/legacy/legacy.service';
import { LoginStatusModel } from '@app/security/shared/login-status-change.model';
import { LoginStatusService } from '@app/security/shared/login-status.service';
import { SettingsService } from '@app/settings/shared/settings.service';
import { WindowService } from '@app/shared/services/browser/window.service';
import { faAngleDoubleRight, faAngleDoubleLeft } from '@fortawesome/pro-light-svg-icons';
import { AuthenticationService } from '@app/security/shared/authentication.service';

const menuCollapseSettingId = 'aujsMenuIsCollapsed';

@Component({
  selector: 'acs-primary-nav-v',
  styleUrls: ['primary-nav-v.scss'],
  templateUrl: 'primary-nav-v.component.html'
})
export class PrimaryNavVComponent {
  @HostBinding('class.collapsed') menuCollapsed = false;
  @HostBinding('class.hide-legacy') hideLegacy = true;
  @HostBinding('class.legacy') legacy = false;

  public collapsedStateIcon = this.menuCollapsed ? faAngleDoubleRight : faAngleDoubleLeft;
  public menuCollapseAppSetting: IAppSetting<any>;
  public userLoggedIn: boolean;

  constructor(
    private authService: AuthenticationService,
    private legacyService: LegacyService,
    private loginStatusService: LoginStatusService,
    private settingsService: SettingsService,
    private windowService: WindowService
  ) {
    this.loginStatusService.loginStatus.subscribe((loginStatus) => {
      this.onLoginStatusChange(loginStatus);
    });

    if (this.windowService.screenSize.screenWidth < 730) {
      this.menuCollapsed = true;
      this.collapsedStateIcon = faAngleDoubleRight;
    }
  }

  public toggleMenuCollapse(): void {
    this.menuCollapsed = !this.menuCollapsed;
    this.collapsedStateIcon = this.menuCollapsed ? faAngleDoubleRight : faAngleDoubleLeft;

    if (this.menuCollapseAppSetting !== undefined && !this.authService.getOnlyAdminStatus()) {
      this.menuCollapseAppSetting.data = this.menuCollapsed;
      this.settingsService.updateSetting(this.menuCollapseAppSetting).subscribe();
    }
  }

  private onCollapsedSettingRetrievedFromService(collapsedSetting): void {
    if (collapsedSetting !== undefined) {
      this.menuCollapseAppSetting = collapsedSetting;
      this.menuCollapsed = this.menuCollapseAppSetting.data;
    } else {
      this.menuCollapseAppSetting = new AppSetting();
      this.menuCollapseAppSetting.id = menuCollapseSettingId;
      this.menuCollapseAppSetting.data = this.menuCollapsed;
      this.settingsService.saveSetting(this.menuCollapseAppSetting).subscribe();
    }

    this.collapsedStateIcon = this.menuCollapsed ? faAngleDoubleRight : faAngleDoubleLeft;
  }

  private onLoginStatusChange(loginStatus: LoginStatusModel): void {
    this.userLoggedIn = loginStatus.loggedIn;
    if (loginStatus.loggedIn) {
      this.settingsService
        .getSettingById(menuCollapseSettingId)
        .subscribe((setting: IAppSetting<any>) => {
          this.onCollapsedSettingRetrievedFromService(setting);
          this.hideLegacy = this.legacyService.hideLegacyForms;
          this.legacy = !this.legacyService.hideLegacyForms;
        });
    } else {
      this.menuCollapseAppSetting = undefined;
    }
  }
}
