<div *ngIf="isShowingRouteLoadIndicator && userLoggedIn" class="router-load-spinner-overlay">
  <i class="fa fa-circle-o-notch fa-spin"></i>
</div>

<div
  class="d-flex flex-fill"
  (contextmenu)="onRightClick($event)">
  <acs-primary-nav-v class="d-flex flex-column" *ngIf="showMenu"></acs-primary-nav-v>

  <div id="content-column" class="d-flex flex-column flex-fill">
    <mat-toolbar class="options-bar">
      <div class="d-flex flex-row align-items-center h-100">
        <button mat-button (click)="toggleMenu()" *ngIf="userLoggedIn" class="toolbar-secondary-button h-100">
          <mat-icon><fa-icon [icon]="toggleMenuIcon" aria-label="Change Menu"></fa-icon></mat-icon>
        </button>
        <button mat-button (click)="toggleWindowPin()"
                *ngIf="userLoggedIn && !adminOnly"
                class="toolbar-secondary-button h-100"
                aria-label="Pin Window">
          <mat-icon color="{{pinColor}}"><fa-icon  [icon]="thumbTackIcon"></fa-icon></mat-icon>
        </button>
        <div id="pageTitle" class="pl-3">
          <span>
            {{ title }}
            <ng-container *ngIf="database"> - {{ database }}</ng-container>
          </span>
        </div>

        <button mat-button (click)="openInNewWindow()"
                *ngIf="userLoggedIn && !adminOnly"
                class="toolbar-secondary-button h-100"
                aria-label="Open the current page in a new window">
          <mat-icon fontIcon="fa-external-link" class="fa"></mat-icon>
        </button>
      </div>
      <acs-toolbar class="d-flex justify-content-end overflow-auto ml-auto h-100"></acs-toolbar>
    </mat-toolbar>
    <mat-divider></mat-divider>

    <div id="content" class="page-content d-flex flex-column flex-fill">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
