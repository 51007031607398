export class LogRequest {
  message: string;
  clientUrl: string;
}

export class FormErrorLogRequest extends LogRequest {
  formId: string;
  formOverrideId: string;
  formIdQueue: string;
}

export class GenericErrorLogRequest extends LogRequest {
  stack: string;
  cause: string;
  name: string;

  constructor(stack: string, cause: string, name: string, errorString: string) {
    super();
    this.clientUrl = window.location.href;
    this.stack = stack;
    this.cause = cause;
    this.name = name;
    this.message = errorString;
  }
}
