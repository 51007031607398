import moment from 'moment';
import 'moment/min/locales';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { AppComponent } from '@app/app.component';
import { AppConfig } from '@app/app.config';
import { BrowserModule } from '@angular/platform-browser';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { DebugComponentService } from '@app/debug/debug-component.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormIdInterceptor } from '@app/security/interceptors/form-id.interceptor';
import { FormsModule } from '@angular/forms';
import { GlobalErrorHandlerService } from '@app/shared/services/error-handler/global-error-handler.service';
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoginStatusService } from '@app/security/shared/login-status.service';
import { Logger } from '@app/logger/logger';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MenuModule } from '@app/menu/menu.module';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { NotificationsModule } from '@app/notifications/notifications.module';
import { SharedModule } from '@app/shared/shared.module';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCog, faPalette, faPowerOff } from '@fortawesome/free-solid-svg-icons';
// import ngx-translate and the http loader
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { UIModule } from '@app/ui/ui.module';
import { UnauthorizedInterceptor } from '@app/security/interceptors/unauthorized.interceptor';
import { appRoutes } from '@app/app.routes';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MAT_CARD_CONFIG } from '@angular/material/card';

library.add(faCog, faPalette, faPowerOff);

const lgSfx = '\t(app.module)';

export function configLoader(config: AppConfig): () => Promise<void> {
  return () => config.load();
}

@NgModule({
  declarations: [AppComponent],
  exports: [],
  bootstrap: [AppComponent],
  imports: [
    // Angular
    BrowserModule,
    NoopAnimationsModule,
    FormsModule,
    MatDialogModule,
    // ngx-translate and http loader
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    // Elements needed for index.html
    MatButtonModule,
    MatIconModule,
    MatToolbarModule,
    FontAwesomeModule,
    // AUJS Modules
    appRoutes,
    NotificationsModule,
    SharedModule,
    // Root UI modules
    MenuModule,
    UIModule,
    MatDividerModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: configLoader,
      deps: [AppConfig],
      multi: true
    },
    MatIconRegistry,
    { provide: MAT_DATE_LOCALE, useValue: navigator.language },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    { provide: HTTP_INTERCEPTORS, useClass: UnauthorizedInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: FormIdInterceptor, multi: true },
    { provide: ErrorHandler, useClass: GlobalErrorHandlerService },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'fill', subscriptSizing: 'dynamic' }
    },
    { provide: MAT_CARD_CONFIG, useValue: { appearance: 'outlined' } },
    MatDialog,
    provideHttpClient(withInterceptorsFromDi())
  ]
})
export class AppModule {
  public pageTitle = 'AUJS';
  private loggedIn = false;

  constructor(
    private appConfig: AppConfig,
    private log: Logger,
    private loginStatusService: LoginStatusService,
    private matIconRegistry: MatIconRegistry,
    private route: ActivatedRoute,
    private router: Router,
    _debugService: DebugComponentService
  ) {
    moment.locale(navigator.language);
    this.setupFontIcons();
    this.watchLoginStatus();
    this.watchRouteChanges();

    log.info(
      `configuration loaded with legacy URI (${this.appConfig.getLegacyUri()}) and server URI (${this.appConfig.getServerUri()}) ${lgSfx}`
    );
  }

  private watchLoginStatus(): void {
    this.loginStatusService.loginStatus.subscribe((loginStatus) => {
      if (loginStatus.loggedIn === false && this.loggedIn === true) {
        this.log.info(`logout detected, redirecting to login route ${lgSfx}`, loginStatus);
        this.loggedIn = false;
        this.router.navigate(['security', 'login']);
      } else {
        this.loggedIn = loginStatus.loggedIn;
      }
    });
  }

  private setupFontIcons(): void {
    this.matIconRegistry.registerFontClassAlias('fontawesome', 'fa');
    this.matIconRegistry.setDefaultFontSetClass('fa');
  }

  private watchRouteChanges(): void {
    this.route.data.subscribe((data: Data) => {
      this.pageTitle = data.title || 'no-title';
    });
  }
}

// required for ahead of time compilation
export function httpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}
