import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2
} from '@angular/core';
import { LegacyService } from '@app/legacy/legacy.service';
import { Menu } from '@app/menu/shared/menu.model';
import { WindowRefService } from '@app/shared/services/browser/window-ref.service';
import { getIdentifierForIterableItem } from '@app/shared/utilities/trackby.utility';

@Component({
  selector: 'acs-menu-toggle',
  templateUrl: './menu-toggle.component.html'
})
export class MenuToggleComponent implements OnInit {
  @Input() section: Menu;
  @Input() showIcon: boolean;
  @Output() menuChanged = new EventEmitter<MenuToggleComponent>();
  @Output() registerSubToggle = new EventEmitter<MenuToggleComponent>();

  public isWideOpen: boolean;
  public isCollapseOpen: boolean;
  public targetHeight: number;
  public getIdentifierForIterableItem = getIdentifierForIterableItem;

  // private currentHoverContainer: HTMLElement;

  constructor(
    public legacyService: LegacyService,
    private el: ElementRef,
    private renderer: Renderer2,
    private windowRefService: WindowRefService
  ) {}

  ngOnInit(): void {
    this.isWideOpen = false;
    this.isCollapseOpen = false;

    if (
      !this.legacyService.hideLegacyForms &&
      (this.section?.name === 'AUJS 2' || this.section?.name === 'Classic')
    ) {
      setTimeout(() => {
        this.toggleWideChildren();
        this.toggleCollapseChildren();
      });
    }
    this.registerSubToggle.emit(this);
  }

  public collapse(): void {
    if (this.isWideOpen) {
      this.toggleWideChildren();
    }
    if (this.isCollapseOpen) {
      this.toggleCollapseChildren();
    }
  }

  public onSubMenuToggled(heightDifference: number): void {
    const subList = this.el.nativeElement.querySelector('.collapse-out.toggleMenuChildren');
    this.targetHeight += heightDifference;
    this.updateComponentHeights(subList);
  }

  public measureHeight(): void {
    let elementHeight =
      this.windowRefService.window.innerHeight -
      this.el.nativeElement.querySelector('.toggleMenuChildren').getBoundingClientRect().top -
      50;

    if (!this.legacyService.hideLegacyForms && elementHeight > 635) {
      elementHeight = 635;
    }

    this.renderer.setStyle(
      this.el.nativeElement.querySelector('.toggleMenuChildren'),
      'max-height',
      elementHeight + 'px'
    );
  }

  public handleScrollBar(el: HTMLElement): void {
    const selector = this.el.nativeElement.querySelector('.toggleMenuChildren_hover:hover')
      ? this.el.nativeElement.querySelector('.toggleMenuChildren_hover:hover')
      : this.el.nativeElement.querySelector('.toggleMenuChildren_hover');
    if (el.getBoundingClientRect().bottom > this.windowRefService.window.innerHeight && selector) {
      const offScreenDiff =
        this.windowRefService.window.innerHeight - el.getBoundingClientRect().bottom;
      this.renderer.setStyle(selector, 'max-height', el.clientHeight + offScreenDiff + 'px');
    }
    if (
      Math.round(el.getBoundingClientRect().bottom) < this.windowRefService.window.innerHeight &&
      selector
    ) {
      const availableScreen = Math.round(
        this.windowRefService.window.innerHeight - el.getBoundingClientRect().bottom
      );
      this.renderer.setStyle(selector, 'max-height', el.clientHeight + availableScreen + 'px');
    }
  }

  public toggleWideChildren(): void {
    const subList = this.el.nativeElement.querySelector('.collapse-out.toggleMenuChildren');
    this.isWideOpen = !this.isWideOpen;
    this.targetHeight = this.isWideOpen ? this.getTargetHeight(subList) : 0;
    this.updateComponentHeights(subList);
  }

  public toggleCollapseChildren(): void {
    this.isCollapseOpen = !this.isCollapseOpen;
  }

  public updateComponentHeights(subList: HTMLElement): void {
    this.menuChanged.emit(this);
    this.renderer.setStyle(subList, 'height', this.targetHeight);
  }

  public getTargetHeight(targetElement: HTMLElement): number {
    this.renderer.addClass(targetElement, 'no-transition');
    this.renderer.setStyle(targetElement, 'height', '');
    this.renderer.removeClass(targetElement, 'no-transition');

    return targetElement.clientHeight;
  }
}
