import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '@app/security/shared/authentication.service';
import { Component, ElementRef, HostListener, OnDestroy, ViewChild } from '@angular/core';
import { ConnectionsService } from '@app/server/shared/connections.service';
import { DomSanitizer } from '@angular/platform-browser';
import { FormService } from '@app/forms/shared/forms.service';
import { LegacyService } from '@app/legacy/legacy.service';
import { Logger } from '@app/logger/logger';
import { LoginStatusModel } from '@app/security/shared/login-status-change.model';
import { LoginStatusService } from '@app/security/shared/login-status.service';
import { MatIconRegistry } from '@angular/material/icon';
import { RoutingStrategyService } from '@app/shared/services/routing-strategy.service';
import { SeatTrackerService } from '@app/security/shared/seat-tracker.service';
import { SecurityService } from '@app/security/shared/security.service';
import { ThemeService } from '@app/shared/services/theme.service';
import { TranslateWrapperService } from '@app/shared/services/translate-wrapper.service';
import { URL_PARAM_HIDE_MENU, WindowService } from '@app/shared/services/browser/window.service';
import { environment } from '@env/environment';
import { faThumbtack, faOutdent, faIndent, faSendBackward } from '@fortawesome/pro-solid-svg-icons';
import { icon } from '@fortawesome/fontawesome-svg-core';

const lgSfx = '\t(app.component)';

@Component({
  selector: 'acs-root',
  styleUrls: ['./app.component.scss'],
  templateUrl: './app.component.html'
})
export class AppComponent implements OnDestroy {
  @ViewChild('spinnerElement') spinnerElement: ElementRef;

  public adminOnly: boolean;
  public database: string;
  public isShowingRouteLoadIndicator = false;
  public pinColor = '#808080';
  public showMenu = true;
  public title = 'Access Unlimited JS';
  public thumbTackIcon = faThumbtack;
  public toggleMenuIcon = this.showMenu ? faOutdent : faIndent;
  public userLoggedIn = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthenticationService,
    private connectionsService: ConnectionsService,
    private domSanitizer: DomSanitizer,
    private formService: FormService,
    private iconRegistry: MatIconRegistry,
    private legacyService: LegacyService,
    private logger: Logger,
    private loginStatusService: LoginStatusService,
    private routingStrategyService: RoutingStrategyService,
    private seatTrackerService: SeatTrackerService,
    private securityService: SecurityService,
    private themeService: ThemeService,
    private translate: TranslateWrapperService,
    private windowService: WindowService
  ) {
    this.themeService.initialize();
    this.setIconsInRegistry();

    this.logger.info(`Running environment ${environment.envName}`);

    this.translate.initializeLanguage();
    this.initializeRouting();

    this.logger.log(`app component loading ${lgSfx}`);
    this.userLoggedIn = this.securityService.getUserLoggedIn();
    this.connectionsService.connectToServer();

    this.loginStatusService.loginStatus.subscribe((loginStatus) => {
      this.onLoginStatusChange(loginStatus);

      this.adminOnly = this.authService.getOnlyAdminStatus();
    });

    this.initWindowSizeInfo();
    this.setKeepAliveEvents();
  }

  @HostListener('window:beforeunload', ['$event'])
  handleClose(event): boolean {
    const form = this.formService.getCurrentForm();

    if (form && form.isDirty) {
      // returnValue = false is required for chromium even though it's deprecated (Jan 2023)
      // event.preventDefault() is required for firefox
      event.preventDefault();
      event.returnValue = false;
      return false;
    }
  }

  public openInNewWindow(): void {
    this.windowService.openCurrentViewInNewTab(this.formService.getCurrentForm());
  }

  public toggleWindowPin(): void {
    this.windowService.toggleWindowPin();

    if (this.windowService.isCurrentWindowPinned()) {
      this.pinColor = 'primary';
    } else {
      this.pinColor = '';
    }
  }

  public onRightClick(event: MouseEvent): void {
    if (event.ctrlKey) {
      return;
    }

    event.preventDefault();
  }

  public toggleMenu(): void {
    this.showMenu = !this.showMenu;
    this.toggleMenuIcon = this.showMenu ? faOutdent : faIndent;
  }

  /*
   * EVENTS
   */

  public ngOnDestroy(): void {
    this.logger.log(`app.component being destroyed ${lgSfx}`);
  }

  private onLoginStatusChange(loginStatus: LoginStatusModel): void {
    this.userLoggedIn = loginStatus.loggedIn;
    this.database = loginStatus.database;
    if (!this.userLoggedIn) {
      this.legacyService.useLegacyIntercom();
    }
  }

  private initWindowSizeInfo(): void {
    this.windowService.screenSize.screenHeight = document.documentElement.clientHeight;
    this.windowService.screenSize.screenWidth = document.documentElement.clientWidth;
    this.windowService.onWindowResize().subscribe((value) => {
      this.logger.log('Window resize event occurred', value);
    });
  }

  private setKeepAliveEvents(): void {
    this.seatTrackerService.checkSeatToken();
    this.seatTrackerService.KeepDbSessionAlive();
  }

  // This allows us to set up pro icons to work with Mat-Icon
  private setIconsInRegistry(): void {
    const svgSendBackward = icon(faSendBackward).html.join('');

    this.iconRegistry.addSvgIconLiteral(
      'send-backwards',
      this.domSanitizer.bypassSecurityTrustHtml(svgSendBackward)
    );
  }

  private initializeRouting(): void {
    this.routingStrategyService.initialize();

    this.routingStrategyService.showRouteLoadIndicator$.subscribe((val: boolean) => {
      this.isShowingRouteLoadIndicator = val;
    });

    this.routingStrategyService.titleChanged$.subscribe((val: string) => {
      this.title = val;
    });

    this.activatedRoute.queryParams.subscribe((params) => {
      if (params[URL_PARAM_HIDE_MENU] === '1') {
        this.showMenu = false;
        this.toggleMenuIcon = this.showMenu ? faOutdent : faIndent;
      }
    });
  }
}
