<button
  mat-button
  color="accent"
  class="collapse-out menu-toggle-button"
  (click)="toggleWideChildren()"
  [ngClass]="{'toggled': isWideOpen}">
  <mat-icon  *ngIf="!section.faIcon; else faIconTemplate" class="fa menu-icon {{ section.icon }}"></mat-icon>
  <ng-template #faIconTemplate>
    <fa-icon class="fa menu-icon" [icon]="section.faIcon"></fa-icon>
  </ng-template>
  {{ section.name }}
  <mat-icon class="fa arrow fa-angle-down"></mat-icon>
</button>
<div class="collapse-out toggleMenuChildren" style="height: 0;" [ngClass]="{'toggled': isWideOpen, 'collapsed-out-legacy': !legacyService.hideLegacyForms}">
  <div *ngFor="let item of section.children; trackBy: getIdentifierForIterableItem">
    <acs-menu-item [item]="item" (menuChanged)="onSubMenuToggled($event)"></acs-menu-item>
  </div>
</div>

<button
  mat-button
  (mouseenter)="measureHeight(); handleScrollBar(elementRef)"
  (click)="toggleCollapseChildren();"
  class="collapse-in menu-default menu-toggle-button"
  [ngClass]="{'toggled': isCollapseOpen}">
  <mat-icon  *ngIf="!section.faIcon; else faIconTemplate" class="fa menu-icon {{ section.icon }}"></mat-icon>
  <ng-template #faIconTemplate>
    <fa-icon class="fa menu-icon" [icon]="section.faIcon"></fa-icon>
  </ng-template>
  <div class="btn-title">{{ section.name }}</div>
  <mat-icon class="fa arrow btn-title fa-angle-down"></mat-icon>
</button>


<div class="collapse-in toggleMenuChildren toggleMenuChildren_hover" #elementRef [ngClass]="{'toggled': isCollapseOpen, 'activeSubMenu': isCollapseOpen}" (mouseenter)="handleScrollBar(elementRef)">
  <button mat-button class="tst-btn menu-toggle-button">{{ section.name }}</button>
  <div *ngFor="let item of section.children; trackBy: getIdentifierForIterableItem">
    <acs-menu-item [item]="item" (menuChanged)="onSubMenuToggled($event)" (click)="handleScrollBar(elementRef)"></acs-menu-item>
  </div>
</div>

