import { CustomPreloadingStrategy } from '@app/shared/services/custom-preloading-strategy.service';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'accounting',
    loadChildren: () => import('@app/accounting/accounting.module').then((m) => m.AccountingModule)
  },
  {
    path: 'administration',
    loadChildren: () =>
      import('@app/administration/administration.module').then((m) => m.AdministrationModule)
  },
  {
    path: 'batch-load-screen',
    loadChildren: () =>
      import('@app/batch-load-screen/batch-load-screen.module').then((m) => m.BatchLoadScreenModule)
  },
  {
    path: 'credit',
    loadChildren: () => import('@app/credit/credit.module').then((m) => m.CreditModule)
  },
  {
    path: 'customers',
    loadChildren: () =>
      import('@app/customers/cutomer-maintenance/customer-maintenance.module').then(
        (m) => m.CustomerMaintenanceModule
      )
  },
  {
    path: 'debug',
    loadChildren: () => import('@app/debug/debug.module').then((m) => m.DebugModule)
  },
  {
    path: 'employees',
    loadChildren: () =>
      import('@app/employees/employee-maintenance/employee-maintenance.module').then(
        (m) => m.EmployeeMaintenanceModule
      )
  },
  {
    path: 'home',
    loadChildren: () => import('@app/home/home.module').then((m) => m.HomeModule),
    data: { preload: true }
  },
  {
    path: 'admin-only',
    loadChildren: () =>
      import('@app/admin-only/admin/admin-only.module').then((m) => m.AdminOnlyModule)
  },
  {
    path: 'inquiry',
    loadChildren: () => import('@app/inquiry/inquiry.module').then((m) => m.InquiryModule)
  },
  {
    path: 'inventory',
    loadChildren: () => import('@app/inventory/inventory.module').then((m) => m.InventoryModule)
  },
  {
    path: 'jobs',
    loadChildren: () =>
      import('@app/jobs/job-maintenance/job-maintenance.routing.module').then(
        (m) => m.JobMaintenanceRoutingModule
      )
  },
  {
    path: 'load-scheduling',
    loadChildren: () =>
      import('@app/truck-tracking/truck-tracking.module').then((m) => m.TruckTrackingModule)
  },
  {
    path: 'adhoc-queries',
    loadChildren: () =>
      import('@app/reports/ad-hoc-queries/ad-hoc-queries.module').then((m) => m.AdHocQueriesModule)
  },
  {
    path: 'operations',
    loadChildren: () => import('@app/operations/operations.module').then((m) => m.OperationsModule)
  },
  {
    path: 'orders',
    loadChildren: () => import('@app/orders/orders.module').then((m) => m.OrdersModule),
    data: { preload: true }
  },
  {
    path: 'plants',
    loadChildren: () => import('@app/plants/plants.module').then((m) => m.PlantsModule)
  },
  {
    path: 'products',
    loadChildren: () => import('@app/products/products.module').then((m) => m.ProductsModule)
  },
  {
    path: 'pumpers',
    loadChildren: () =>
      import('@app/pumper/pumper-maintenance/pumper-maintenance.module').then(
        (m) => m.PumperMaintenanceModule
      )
  },
  {
    path: 'quote',
    loadChildren: () => import('@app/quotes/quotes.module').then((m) => m.QuotesModule)
  },
  {
    path: 'reports',
    loadChildren: () =>
      import('@app/reports/custom-reports/custom-reports.module').then((m) => m.CustomReportsModule)
  },
  {
    path: 'reports',
    loadChildren: () =>
      import('@app/reports/custom-reports/custom-reports.module').then((m) => m.CustomReportsModule)
  },
  {
    path: 'security',
    loadChildren: () => import('@app/security/security.module').then((m) => m.SecurityModule)
  },
  {
    path: 'ticketing',
    loadChildren: () => import('@app/ticketing/ticketing.module').then((m) => m.TicketingModule)
  },
  {
    path: 'credit',
    loadChildren: () => import('@app/credit/credit.module').then((m) => m.CreditModule)
  },
  {
    path: 'products',
    loadChildren: () => import('@app/products/products.module').then((m) => m.ProductsModule)
  },
  {
    path: 'trucks',
    loadChildren: () => import('@app/trucks/trucks.module').then((m) => m.TrucksModule)
  },
  {
    path: '',
    loadChildren: () => import('@app/security/login/login.module').then((m) => m.LoginModule)
  },
  {
    path: '**',
    redirectTo: 'home'
  }
];

export const appRoutes = RouterModule.forRoot(routes, {
  enableTracing: false,
  preloadingStrategy: CustomPreloadingStrategy
});
