import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { IMenu, MenuConstructorParams } from '@app/menu/shared/menu.interface';

export class Menu implements IMenu {
  alwaysShow: boolean;
  children: Array<IMenu>;
  enabled: boolean;
  formEnumId: string;
  icon: string;
  faIcon: IconDefinition;
  formId: number;
  name: string;
  type: 'form' | 'section';
  uuid: string;
  systemTypeId?: number;

  constructor(params: MenuConstructorParams) {
    this.alwaysShow = params.alwaysShow || false;
    this.children = params.children;
    this.enabled = params.enabled || false;
    this.formEnumId = params.formEnumId;
    this.icon = params.icon || '';
    this.faIcon = params.faIcon;
    this.formId = params.formId;
    this.name = params.name;
    this.type = params.type;
    this.uuid = params.uuid;
    this.systemTypeId = params.systemTypeId;
  }
}
