import { AppConfig } from '@app/app.config';
import { FormErrorLogRequest, GenericErrorLogRequest } from '@app/logger/api/log-request.model';
import { HttpService } from '@app/security/shared/http.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class APILoggerService {
  constructor(private config: AppConfig, private http: HttpService) {}

  public sendFormErrorLog(request: FormErrorLogRequest): Observable<any> {
    const endpoint = this.config.getServerUri() + `/api/v1/log/form-auth-error`;

    return this.http.postAny(endpoint, request);
  }

  public sendGenericErrorLog(request: GenericErrorLogRequest): Observable<any> {
    const endpoint = this.config.getServerUri() + `/api/v1/log/generic`;

    return this.http.postAny(endpoint, request);
  }
}
