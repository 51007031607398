import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MenuComponent } from '@app/menu/menu/menu.component';
import { MenuItemComponent } from '@app/menu/menu-toggle/menu-item.component';
import { MenuToggleComponent } from '@app/menu/menu-toggle/menu-toggle.component';
import { NgModule } from '@angular/core';
import { PrimaryNavVComponent } from '@app/menu/primary-nav-v/primary-nav-v.component';
import { TranslateModule } from '@ngx-translate/core';
import { UIModule } from '@app/ui/ui.module';

@NgModule({
  declarations: [MenuComponent, MenuItemComponent, MenuToggleComponent, PrimaryNavVComponent],
  exports: [MenuComponent, MenuItemComponent, MenuToggleComponent, PrimaryNavVComponent],
  imports: [
    CommonModule,
    FormsModule,
    FontAwesomeModule,
    MatButtonModule,
    MatIconModule,
    MatToolbarModule,
    UIModule,
    TranslateModule
  ]
})
export class MenuModule {}
