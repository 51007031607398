import {
  ActivatedRoute,
  Data,
  Event,
  NavigationCancel,
  NavigationEnd,
  NavigationStart,
  ResolveStart,
  Router
} from '@angular/router';
import { ENUM_ID_Q_PARAM, FORM_ID_HEADER } from '@app/shared/constants/constants';
import { FormService } from '@app/forms/shared/forms.service';
import { Injectable } from '@angular/core';
import { Logger } from '@app/logger/logger';
import { Observable, Subject } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { filter, map, mergeMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class RoutingStrategyService {
  private asyncLoadCount = 0;
  private initialized = false;
  private showRouteLoadIndicatorSub = new Subject<boolean>();
  private titleChangedSub = new Subject<string>();

  public get showRouteLoadIndicator$(): Observable<boolean> {
    return this.showRouteLoadIndicatorSub.asObservable();
  }

  public get titleChanged$(): Observable<string> {
    return this.titleChangedSub.asObservable();
  }

  constructor(
    private activatedRoute: ActivatedRoute,
    private formService: FormService,
    private logger: Logger,
    private router: Router,
    private titleService: Title
  ) {}

  public initialize(): void {
    if (this.initialized) {
      return;
    }

    this.subscribeToAllRouteEvents();
    this.subscribeToResolveStart();
    this.subscribeToNavigationEnd();
    this.initialized = true;
  }

  private subscribeToAllRouteEvents(): void {
    this.router.events.subscribe((event: Event): void => {
      if (event instanceof NavigationStart) {
        this.asyncLoadCount++;
      } else if (event instanceof NavigationEnd || event instanceof NavigationCancel) {
        this.asyncLoadCount--;
      }

      this.showRouteLoadIndicatorSub.next(!!this.asyncLoadCount);
    });
  }

  private subscribeToResolveStart(): void {
    this.router.events
      .pipe(filter((event) => !!event === true && event instanceof ResolveStart))
      .subscribe((ar: ResolveStart) => {
        let destinationRoute = ar.state.root?.firstChild;
        while (destinationRoute?.firstChild) {
          destinationRoute = destinationRoute.firstChild;
        }

        const formIdQuery =
          parseInt(ar.state.root.queryParams[FORM_ID_HEADER], 10) ||
          destinationRoute?.data['formId'];

        if (formIdQuery) {
          this.logger.log('ResolveStart setting form from id', formIdQuery);
          this.formService.setCurrentForm(formIdQuery, ar.url);
        }
      });
  }

  private subscribeToNavigationEnd(): void {
    this.router.events
      .pipe(
        filter((event) => !!event === true && event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route: ActivatedRoute) => {
          while (route.firstChild) {
            route = route.firstChild;
          }

          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        mergeMap((route) => route.data)
      )
      .subscribe((routeData: Data) => {
        this.titleService.setTitle(routeData['title']);
        this.titleChangedSub.next(this.titleService.getTitle());

        const routeFormEnumId =
          routeData['formEnumId'] || this.activatedRoute.snapshot.queryParams[ENUM_ID_Q_PARAM];

        if (routeFormEnumId) {
          this.formService.setCurrentFormByEnumId(routeFormEnumId);
        } else {
          this.formService.setCurrentForm(routeData['formId'], this.router.url);
        }
      });
  }
}
