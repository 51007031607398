import { APILoggerService } from '@app/logger/api/api-logger.service';
import { AppConfig } from '@app/app.config';
import { ErrorHandler, Injectable, OnDestroy } from '@angular/core';
import { GenericErrorLogRequest } from '@app/logger/api/log-request.model';
import { Logger } from '@app/logger/logger';
import { Subscription, interval, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { hasValue } from '@app/shared/utilities/comparison-helpers.utility';

const INTERVAL_CHECK = 3_600_000; // 1 hour

/**
 * Service that implements ErrorHandler meaning it will intercept all uncaught
 * client side errors for the module it is provided in.
 * It can be provided on a per module basis. It is currently
 * provided at the app module level so it will be active for the entire application.
 */
@Injectable({
  providedIn: 'root'
})
export class GlobalErrorHandlerService implements ErrorHandler, OnDestroy {
  private _loggedErrors = new Set<string>();
  private intervalSubscription = new Subscription();

  constructor(
    private apiLogger: APILoggerService,
    private appConfig: AppConfig,
    private logger: Logger
  ) {
    this.setInterval();
  }

  ngOnDestroy(): void {
    this.intervalSubscription.unsubscribe();
  }

  handleError(error: any): void {
    console.error(error);

    if (!this.appConfig.enableErrorReporting) {
      return;
    }

    if (!hasValue(error)) {
      console.warn('Error was null/undefined');
      return;
    }

    const errorString = error.toString();
    if (!this._loggedErrors.has(errorString)) {
      const errorData = new GenericErrorLogRequest(
        error.stack || error,
        error.cause,
        error.name,
        errorString
      );
      this._loggedErrors.add(errorString);

      this.apiLogger.sendGenericErrorLog(errorData).subscribe({
        next: (resp) => {
          this.logger.log(resp);
        },
        error: (serverError) => {
          this.logger.error(serverError);
        }
      });
    }
  }

  private setInterval(): void {
    this.intervalSubscription = interval(INTERVAL_CHECK)
      .pipe(
        catchError((error) => {
          console.error('Error with interval', error);
          return of(undefined);
        })
      )
      .subscribe({
        next: () => {
          try {
            this._loggedErrors.clear();
            this.logger.info('Cleared logged errors');
          } catch (e) {
            console.error('Error caught in interval subscription', e);
          }
        }
      });
  }
}
