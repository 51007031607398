import { AUJS_FORMS } from '@app/forms/shared/aujs-forms';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FORM_ID_HEADER } from '@app/shared/constants/constants';
import { Observable } from 'rxjs';
import { SecurityService } from '@app/security/shared/security.service';
import { FormService } from '@app/forms/shared/forms.service';

@Injectable()
export class FormIdInterceptor implements HttpInterceptor {
  constructor(private formService: FormService, private securityService: SecurityService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.securityService.getUserLoggedIn() && !request.headers.has(FORM_ID_HEADER)) {
      const formId =
        this.formService.formOverrideId ||
        this.formService.getCurrentForm()?.id ||
        AUJS_FORMS.SystemForm.id;

      request = request.clone({
        headers: request.headers.set(FORM_ID_HEADER, formId.toString(10))
      });
    }

    return next.handle(request);
  }
}
