import { AppConfig } from '@app/app.config';
import { AUJS_FORMS } from '@app/forms/shared/aujs-forms';
import { AuthorizationService } from '@app/security/shared/authorization.service';
import { Form } from '@app/forms/form.model';
import { GuidService } from '@app/shared/services/guid.service';
import { IconDefinition, faFileLines } from '@fortawesome/pro-regular-svg-icons';
import { IMenu, MenuConstructorParams } from '@app/menu/shared/menu.interface';
import { Injectable } from '@angular/core';
import { LegacyService } from '@app/legacy/legacy.service';
import { Menu } from '@app/menu/shared/menu.model';
import { MenuLoadData } from '@app/menu/shared/menu-load-data.model';
import { TranslateWrapperService } from '@app/shared/services/translate-wrapper.service';

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  constructor(
    private appConfig: AppConfig,
    private authorizationService: AuthorizationService,
    private legacyService: LegacyService,
    private guidService: GuidService,
    private translate: TranslateWrapperService
  ) {}

  public getMenuItems(data: MenuLoadData): Array<IMenu> {
    return this.getFullMenu(data).filter((item: IMenu) => this.itemShouldBeShown(item, data));
  }

  // public removeToggleMenuHoverClassFromDOM(): void {
  //   const hoverElementList = document.getElementsByClassName('toggleMenuChildren_hover');
  //
  //   const numOfEls = hoverElementList.length;
  //   if (hoverElementList && numOfEls) {
  //     for (let i = 0; i < numOfEls; i++) {
  //       hoverElementList[0].classList.remove('toggleMenuChildren_hover');
  //     }
  //   }
  // }

  public getAdminMenu(isAdmin: boolean, enableExternalUsers: boolean): IMenu {
    if (!isAdmin) {
      return undefined;
    }
    const externalUserForm = enableExternalUsers
      ? this.getFormMenuObject('FormNgExternalUserMaintenance')
      : undefined;

    return this.getSectionObject(
      this.translate.instant('acs-menu.administration.label'),
      [
        this.getFormMenuObject('FormNgCreditStatus'),
        this.getFormMenuObject('FormNgGLCategoryMaintenance'),
        this.getFormMenuObject('FormNgGroupMaintenance'),
        this.getFormMenuObject('FormNgProductPriceEscalation'),
        this.getFormMenuObject('FormNgQuotePriceEscalation'),
        this.getFormMenuObject('FormNgShortLoadMaintenance'),
        this.getFormMenuObject('FormNgStaticProducts'),
        this.getFormMenuObject('FormNgSystemParameters'),
        this.getFormMenuObject('FormNgTaxLevelMaintenance'),
        this.getFormMenuObject('FormNgTaxScheduleMaintenance'),
        this.getFormMenuObject('FormNgTermsDiscount'),
        this.getFormMenuObject('FormNgTruckTypeMaintenance'),
        this.getFormMenuObject('FormNgUserMaintenance'),
        externalUserForm
        // this.getFormMenuObject('FormNgReportSchedule')
      ],
      'fa-wrench'
    );
  }

  private getFormMenuObject(formEnumId: string): IMenu {
    const form: Form = AUJS_FORMS[formEnumId];
    const menuParams: MenuConstructorParams = {};
    if (form) {
      let alwaysShow: boolean = form.alwaysShow;
      if (this.legacyService.hideLegacyForms && form.legacy) {
        alwaysShow = false;
      }

      menuParams.name = form.name;
      menuParams.type = 'form';
      menuParams.enabled = form.enabled;
      menuParams.formEnumId = form.enumId;
      menuParams.formId = form.id;
      menuParams.alwaysShow = alwaysShow;
      menuParams.systemTypeId = form.systemTypeId;
    } else {
      menuParams.name = formEnumId;
      menuParams.type = 'form';
      menuParams.enabled = false;
      menuParams.formEnumId = formEnumId;
    }

    return new Menu(menuParams);
  }

  private getSectionObject(
    name: string,
    children: Array<IMenu>,
    icon?: string | IconDefinition
  ): IMenu {
    const menuParams: MenuConstructorParams = {};

    menuParams.name = name;
    menuParams.type = 'section';
    menuParams.enabled = true;
    menuParams.formEnumId = null;
    menuParams.children = children;
    menuParams.uuid = this.guidService.newGuid();

    if (icon) {
      if (typeof icon === 'string') {
        menuParams.icon = icon;
      } else {
        menuParams.faIcon = icon;
      }
    }

    return new Menu(menuParams);
  }

  private itemShouldBeShown(item: IMenu, menuOpts: MenuLoadData): boolean {
    switch (item?.type) {
      case 'form':
        if (this.legacyService.hideLegacyForms) {
          // ? getter
          const forms: Array<Form> = Object.values(AUJS_FORMS);
          const form: Form = forms.find((formItem: Form) => formItem.enumId === item.formEnumId);

          if (form && form.legacy) {
            return false;
          }
        }

        if (item.systemTypeId) {
          if (!menuOpts.systemTypes.has(item.systemTypeId)) {
            return false;
          }
        }

        if (
          this.appConfig.disabledForms?.length &&
          this.appConfig.disabledForms.includes(item.formEnumId)
        ) {
          return false;
        }

        return (
          item.alwaysShow || this.authorizationService.getFormPermission(item.formId).userCanView()
        );
      case 'section':
        item.children = item.children.filter((children: IMenu) =>
          this.itemShouldBeShown(children, menuOpts)
        );
        return item.children.length > 0;
      default:
        return false;
    }
  }

  private getFullMenu(menuData: MenuLoadData): IMenu[] {
    return [
      this.getDashboardMenu(),
      this.getSalesMenu(),
      this.getTicketProceduresMenu(),
      this.getOperationsMenu(menuData),
      this.getInventoryMenu(),
      this.getCreditMenu(),
      this.getReportMenu(menuData.nlqEnabled),
      this.getFormMenuObject('FormReports'),
      this.getFormMenuObject('FormAbout'),
      this.getAdminMenu(menuData.isAdmin, menuData.externalUsersEnabled)
    ];
  }

  private getDashboardMenu(): IMenu {
    const dashboardFormsArray = [this.getFormMenuObject('FormNgDashboard')];

    return this.getSectionObject(
      this.translate.instant('acs-menu.dashboard.label'),
      dashboardFormsArray,
      'fa-tachometer'
    );
  }

  private getReportMenu(includeNLQ: boolean): IMenu {
    const reportFormsArray = [this.getFormMenuObject('FormNgReports')];

    if (includeNLQ) {
      reportFormsArray.push(this.getFormMenuObject('FormNgNLQ'));
    }

    return this.getSectionObject(
      this.translate.instant('acs-menu.reports.label'),
      reportFormsArray,
      faFileLines
    );
  }

  private getSalesMenu(): IMenu {
    if (this.legacyService.hideLegacyForms) {
      return this.getSectionObject(
        this.translate.instant('acs-menu.sales.label'),
        [
          this.getSectionObject(this.translate.instant('acs-menu.sales.audit-orders.label'), [
            this.getFormMenuObject('FormNgAuditOrderConcrete'),
            this.getFormMenuObject('FormNgAuditOrderAggregate'),
            this.getFormMenuObject('FormNgAuditOrderBlock')
          ]),
          this.getFormMenuObject('FormNgQuoteMaintenance'),
          this.getFormMenuObject('FormNgJobMaintenance'),
          this.getSectionObject(
            this.translate.instant('acs-menu.sales.product-maintenance.label'),
            [
              this.getFormMenuObject('FormNgConcreteProductMaintenance'),
              this.getFormMenuObject('FormNgAggregateProductMaintenance'),
              this.getFormMenuObject('FormNgBlockProductMaintenance'),
              this.getFormMenuObject('FormNgOtherProductMaintenance'),
              this.getFormMenuObject('FormNgRawMaterialProductMaintenance')
            ]
          )
        ],
        'fa-usd'
      );
    } else {
      return this.getSectionObject(
        this.translate.instant('acs-menu.sales.label'),
        [
          this.getSectionObject('AUJS 2', [
            this.getSectionObject(this.translate.instant('acs-menu.sales.audit-orders.label'), [
              this.getFormMenuObject('FormNgAuditOrderConcrete'),
              this.getFormMenuObject('FormNgAuditOrderAggregate'),
              this.getFormMenuObject('FormNgAuditOrderBlock')
            ]),
            this.getFormMenuObject('FormNgQuoteMaintenance'),
            this.getFormMenuObject('FormNgJobMaintenance'),
            this.getSectionObject(
              this.translate.instant('acs-menu.sales.product-maintenance.label'),
              [
                this.getFormMenuObject('FormNgConcreteProductMaintenance'),
                this.getFormMenuObject('FormNgAggregateProductMaintenance'),
                this.getFormMenuObject('FormNgBlockProductMaintenance'),
                this.getFormMenuObject('FormNgOtherProductMaintenance'),
                this.getFormMenuObject('FormNgRawMaterialProductMaintenance')
              ]
            )
          ]),

          this.getSectionObject(
            'Classic',
            [
              this.getFormMenuObject('FormProduct'),
              this.getFormMenuObject('FormBid'),
              this.getFormMenuObject('FormQuote'),
              this.getFormMenuObject('FormJob'),
              this.getSectionObject(
                'Classic Audit Orders',
                [
                  this.getFormMenuObject('FormAuditOrderConcrete'),
                  this.getFormMenuObject('FormAuditOrderAggregate'),
                  this.getFormMenuObject('FormAuditOrderBlock')
                ],
                undefined
              ),
              this.getFormMenuObject('FormDirections')
            ],
            undefined
          )
        ],
        'fa-usd'
      );
    }
  }

  private getTicketProceduresMenu(): IMenu {
    if (this.legacyService.hideLegacyForms) {
      return this.getSectionObject(
        this.translate.instant('acs-menu.ticket-procedures.label'),
        [
          this.getFormMenuObject('FormNgEditTickets'),
          this.getFormMenuObject('FormNgBatchEditTicket'),
          this.getFormMenuObject('FormNgExportTicketData'),
          this.getFormMenuObject('FormNgInvoiceFlag'),
          this.getFormMenuObject('FormNgInvoiceInquiry'),
          this.getFormMenuObject('FormNgTicketInquiry')
        ],
        'fa-columns'
      );
    } else {
      return this.getSectionObject(
        this.translate.instant('acs-menu.ticket-procedures.label'),
        [
          this.getSectionObject(
            'AUJS 2',
            [
              this.getFormMenuObject('FormNgEditTickets'),
              this.getFormMenuObject('FormNgBatchEditTicket'),
              this.getFormMenuObject('FormNgInvoiceFlag'),
              this.getFormMenuObject('FormNgInvoiceInquiry'),
              this.getFormMenuObject('FormNgTicketInquiry')
            ],
            undefined
          ),

          this.getSectionObject(
            'Classic',
            [
              this.getFormMenuObject('FormTicketsReceive'),
              this.getFormMenuObject('FormTicketEdit'),
              this.getFormMenuObject('FormTicketEditBatch'),
              this.getFormMenuObject('FormTicketsExport'),
              this.getFormMenuObject('FormInvoiceFlag'),
              this.getFormMenuObject('FormTicketInquiry'),
              this.getFormMenuObject('FormInvoiceInquiry')
            ],
            undefined
          )
        ],
        'fa-columns'
      );
    }
  }

  private getOperationsMenu(menuOpts: MenuLoadData): IMenu {
    const concreteQuickSale = menuOpts.systemParameters.concreteAutoPrimaryProductId
      ? this.getFormMenuObject('FormNgQuickSaleConcrete')
      : undefined;

    const aggQuickSale = menuOpts.systemParameters.aggregateAutoPrimaryProductId
      ? this.getFormMenuObject('FormNgQuickSaleAggregate')
      : undefined;

    if (this.legacyService.hideLegacyForms) {
      return this.getSectionObject(
        this.translate.instant('acs-menu.operations.label'),
        [
          this.getSectionObject(this.translate.instant('acs-menu.operations.concrete.label'), [
            this.getFormMenuObject('FormNgOrderEntryConcrete'),
            concreteQuickSale,
            this.getFormMenuObject('FormNgRawMaterialRanking'),
            this.getFormMenuObject('FormNgMixDesignChange'),
            this.getFormMenuObject('FormNgConcreteMixModification')
          ]),
          this.getSectionObject(this.translate.instant('acs-menu.operations.aggregate.label'), [
            this.getFormMenuObject('FormNgOrderEntryAggregate'),
            this.getFormMenuObject('FormNgAggregateTicketing'),
            aggQuickSale,
            this.getFormMenuObject('FormNgDeliveryChargeMaintenance')
          ]),
          this.getSectionObject(this.translate.instant('acs-menu.operations.block.label'), [
            this.getFormMenuObject('FormNgOrderEntryBlock')
          ]),
          this.getFormMenuObject('FormNgLoadSchedulingScreen'),
          this.getFormMenuObject('FormNgTruckMaintenance'),
          this.getFormMenuObject('FormNgTruckCompanyMaintenance'),
          this.getFormMenuObject('FormNgBatchLoadScreen'),
          this.getFormMenuObject('FormNgEditTicketTimes'),
          this.getFormMenuObject('FormNgPlantMaintenance'),
          this.getFormMenuObject('FormNgPumperMaintenance'),
          this.getFormMenuObject('FormNgEmployeeMaintenance')
        ],
        'fa-cogs'
      );
    } else {
      return this.getSectionObject(
        this.translate.instant('acs-menu.operations.label'),
        [
          this.getSectionObject(
            'AUJS 2',
            [
              this.getSectionObject(
                this.translate.instant('acs-menu.operations.concrete.label'),
                [
                  this.getFormMenuObject('FormNgOrderEntryConcrete'),
                  this.getFormMenuObject('FormNgRawMaterialRanking'),
                  this.getFormMenuObject('FormNgConcreteMixModification')
                ],
                undefined
              ),
              this.getSectionObject(
                this.translate.instant('acs-menu.operations.aggregate.label'),
                [
                  this.getFormMenuObject('FormNgOrderEntryAggregate'),
                  this.getFormMenuObject('FormNgAggregateTicketing')
                ],
                undefined
              ),
              this.getSectionObject(
                this.translate.instant('acs-menu.operations.block.label'),
                [this.getFormMenuObject('FormNgOrderEntryBlock')],
                undefined
              ),
              this.getFormMenuObject('FormNgLoadSchedulingScreen'),
              this.getFormMenuObject('FormNgTruckMaintenance'),
              this.getFormMenuObject('FormNgTruckCompanyMaintenance'),
              this.getFormMenuObject('FormNgBatchLoadScreen'),
              this.getFormMenuObject('FormNgEditTicketTimes'),
              this.getFormMenuObject('FormNgPlantMaintenance'),
              this.getFormMenuObject('FormNgPumperMaintenance'),
              this.getFormMenuObject('FormNgEmployeeMaintenance')
            ],
            undefined
          ),

          this.getSectionObject(
            'Classic',
            [
              this.getSectionObject(
                'Concrete',
                [
                  this.getFormMenuObject('FormOrderEntryConcreteBasic'),
                  this.getFormMenuObject('FormOrderEntryConcrete'),
                  this.getFormMenuObject('FormTicketConcrete'),
                  this.getFormMenuObject('FormCalculatorConcrete'),
                  this.getFormMenuObject('FormMixDesignModification'),
                  this.getFormMenuObject('FormMixDesignChange'),
                  this.getFormMenuObject('FormRawMaterialRanking'),
                  this.getFormMenuObject('FormAdmixDosingByTravelMinutes')
                ],
                undefined
              ),
              this.getSectionObject(
                'Aggregate',
                [
                  this.getFormMenuObject('FormOrderEntryAggregate'),
                  this.getFormMenuObject('FormTicketAggregate'),
                  this.getFormMenuObject('FormTicketAggregateQuick'),
                  this.getFormMenuObject('FormDeliveryCharge'),
                  this.getFormMenuObject('FormMixDesignAggregate'),
                  this.getFormMenuObject('FormTrailer'),
                  this.getFormMenuObject('FormOrderEntryRail'),
                  this.getFormMenuObject('FormScheduleRail'),
                  this.getFormMenuObject('FormCommodityCodeRail')
                ],
                undefined
              ),
              this.getSectionObject(
                'Block',
                [
                  this.getFormMenuObject('FormOrderEntryBlock'),
                  this.getFormMenuObject('FormTicketBlock'),
                  this.getFormMenuObject('FormCalculatorBlock')
                ],
                undefined
              ),
              this.getFormMenuObject('FormLoadSchedulingScreen'),
              this.getFormMenuObject('FormTruckDemand'),
              this.getFormMenuObject('FormTruckDemandManager'),
              this.getFormMenuObject('FormLoadDemand'),
              this.getFormMenuObject('FormTruck'),
              this.getFormMenuObject('FormMapTravelTimes'),
              this.getFormMenuObject('FormTicketEditTimes'),
              this.getFormMenuObject('FormTruckCompany'),
              this.getFormMenuObject('FormBatchLoadScreen'),
              this.getFormMenuObject('FormBatchLoadScreenBlock'),
              this.getFormMenuObject('FormPlant'),
              this.getFormMenuObject('FormPumper'),
              this.getFormMenuObject('FormEmployee'),
              this.getFormMenuObject('FormEmployeeTimes')
            ],
            undefined
          )
        ],
        'fa-cogs'
      );
    }
  }
  // commented out for no use
  private getInventoryMenu(): IMenu {
    if (this.legacyService.hideLegacyForms) {
      return this.getSectionObject(
        'Inventory',
        [
          this.getFormMenuObject('FormNgVendorMaintenance'),
          this.getFormMenuObject('FormNgHaulerMaintenance'),
          this.getFormMenuObject('FormNgSourceMaintenance'),
          this.getFormMenuObject('FormNgMaterialReceiptMaintenance'),
          this.getFormMenuObject('FormNgInventoryAdjustments')
        ],
        'fa-th-list'
      );
    } else {
      return this.getSectionObject(
        'Inventory',
        [
          this.getSectionObject(
            'AUJS 2',
            [
              this.getFormMenuObject('FormNgHaulerMaintenance'),
              this.getFormMenuObject('FormNgMaterialReceiptMaintenance')
            ],
            ''
          ),
          this.getSectionObject(
            'Classic',
            [
              this.getFormMenuObject('FormVendor'),
              this.getFormMenuObject('FormHauler'),
              this.getFormMenuObject('FormSource'),
              this.getFormMenuObject('FormMaterialReceipt'),
              this.getFormMenuObject('FormMaterialReceiptsReceive'),
              this.getFormMenuObject('FormMaterialInvoice'),
              this.getFormMenuObject('FormInventoryAdjustment'),
              this.getFormMenuObject('FormInventoryPhysicalCount'),
              this.getFormMenuObject('FormInventoryPhysicalCountBatch')
            ],
            ''
          )
        ],
        'fa-th-list'
      );
    }
  }

  private getCreditMenu(): IMenu {
    if (this.legacyService.hideLegacyForms) {
      return this.getSectionObject(
        'Credit',
        [
          this.getFormMenuObject('FormNgCustomerMaintenance'),
          this.getFormMenuObject('FormNgLetter'),
          this.getFormMenuObject('FormNgLetterMaintenance'),
          this.getFormMenuObject('FormNgPreliminaryNoticeInput'),
          this.getFormMenuObject('FormNgPreliminaryNoticePrint')
        ],
        'fa-plus-square'
      );
    } else {
      return this.getSectionObject(
        this.translate.instant('acs-menu.credit.label'),
        [
          this.getSectionObject(
            'AUJS 2',
            [
              this.getFormMenuObject('FormNgCustomerMaintenance'),
              this.getFormMenuObject('FormNgLetter'),
              this.getFormMenuObject('FormNgLetterMaintenance'),
              this.getFormMenuObject('FormNgPreliminaryNoticeInput'),
              this.getFormMenuObject('FormNgPreliminaryNoticePrint')
            ],
            undefined
          ),

          this.getSectionObject(
            'Classic',
            [
              this.getFormMenuObject('FormCustomer'),
              this.getFormMenuObject('FormLabels'),
              this.getFormMenuObject('FormLetterMaintenance'),
              this.getFormMenuObject('FormLetter'),
              this.getFormMenuObject('FormPrelim'),
              this.getFormMenuObject('FormPrelimPrint')
            ],
            undefined
          )
        ],
        'fa-plus-square'
      );
    }
  }
}
