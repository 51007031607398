<div [ngSwitch]="item.type" class="menu-item">
  <acs-menu-toggle
    *ngSwitchCase="'section'"
    [section]="item"
    (menuChanged)="onSubMenuToggled($event)"
    (registerSubToggle)="registerToggle($event)"
    [showIcon]="true">
  </acs-menu-toggle>
  <acs-form-link *ngSwitchCase="'form'" [formId]="item.formEnumId" [showIcon]="true"></acs-form-link>
  <div *ngSwitchDefault><button mat-button color="warn" [disabled]="true">{{ item.name }}</button></div>
</div>
