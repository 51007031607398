import { APILoggerService } from '@app/logger/api/api-logger.service';
import { AuthorizationService } from '@app/security/shared/authorization.service';
import { AuthGuard } from '@app/security/shared/auth-guard.service';
import { FormErrorLogRequest } from '@app/logger/api/log-request.model';
import { FormService } from '@app/forms/shared/forms.service';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocalStorageRefService } from '@app/shared/services/browser/local-storage-ref.service';
import { Logger } from '@app/logger/logger';
import { Observable } from 'rxjs';
import { SecurityService } from '@app/security/shared/security.service';
import { tap } from 'rxjs/operators';

@Injectable()
export class UnauthorizedInterceptor implements HttpInterceptor {
  constructor(
    private apiLogger: APILoggerService,
    private authorizationService: AuthorizationService,
    private formService: FormService,
    private localStoreageServcie: LocalStorageRefService,
    private logger: Logger,
    private securityServcie: SecurityService,
    private authGuard: AuthGuard
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = request.clone({
      headers: request.headers.set('seat_token', this.localStoreageServcie.seatToken)
    });

    return next.handle(request).pipe(
      tap(
        () => {},
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              this.authGuard.clearRequestedUrl();
              this.logger.log(
                'Received a 401 from the server, logging user out',
                request,
                err,
                '\t(unauthorized.interceptor.ts)'
              );
              this.securityServcie.forceLogout();
            } else if (err.status === 403) {
              this.authorizationService.displayInsufficientPermissionDialog(err.url);
              this.sendFormErrorLog();
              this.logger.log(
                'Received a 403 from the server',
                request,
                err,
                '\t(unauthorized.interceptor.ts)'
              );
            }

            return;
          }
        }
      )
    );
  }

  private sendFormErrorLog(): void {
    const request = new FormErrorLogRequest();
    request.clientUrl = window.location.href;
    request.formId = this.formService.getCurrentForm()?.id?.toString() || 'undefined';
    request.formOverrideId = this.formService.formOverrideId?.toString() || 'undefined';
    request.formIdQueue = this.formService.formOverrideIdQ?.toString() || 'undefined';

    this.apiLogger.sendFormErrorLog(request).subscribe({
      next: (resp) => {
        this.logger.log(resp);
      },
      error: (error) => {
        this.logger.error(error);
      }
    });
  }
}
