import { AppConfig } from '@app/app.config';
import { AuthenticationService } from '@app/security/shared/authentication.service';
import { AuthorizationService } from '@app/security/shared/authorization.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Logger } from '@app/logger/logger';
import { LoginStatusModel } from '@app/security/shared/login-status-change.model';
import { LoginStatusService } from '@app/security/shared/login-status.service';
import { Menu } from '@app/menu/shared/menu.model';
import { MenuLoadData, MenuLoadDataObs } from '@app/menu/shared/menu-load-data.model';
import { MenuService } from '@app/menu/shared/menu.service';
import { MenuToggleComponent } from '@app/menu/menu-toggle/menu-toggle.component';
import { MenuToggleDirectorService } from '@app/menu/menu-toggle/menu-toggle-director.service';
import { SecurityService } from '@app/security/shared/security.service';
import { SystemParameterService } from '@app/system-parameters/shared/system-parameters.service';
import { SystemTypeService } from '@app/shared/services/system-type/system-type.service';
import { catchError } from 'rxjs/operators';
import { forkJoin, of, Subscription } from 'rxjs';
import { getIdentifierForIterableItem } from '@app/shared/utilities/trackby.utility';

@Component({
  selector: 'acs-menu',
  templateUrl: './menu.component.html'
})
export class MenuComponent implements OnInit, OnDestroy {
  public getIdentifierForIterableItem = getIdentifierForIterableItem;
  public items: Array<Menu>;
  public loginSubscription: Subscription;
  public showMenu: boolean;

  constructor(
    private config: AppConfig,
    private authorizationService: AuthorizationService,
    private authenticationService: AuthenticationService,
    private logger: Logger,
    private loginStatusService: LoginStatusService,
    private menuService: MenuService,
    private menuToggleDirectorService: MenuToggleDirectorService,
    private securityService: SecurityService,
    private systemParameterService: SystemParameterService,
    private systemTypeService: SystemTypeService
  ) {}

  ngOnInit(): void {
    this.showMenu = this.securityService.getUserLoggedIn();
    this.loginSubscription = this.loginStatusService.loginStatus.subscribe(
      (loginStatus: LoginStatusModel) => {
        this.showMenu = loginStatus.loggedIn;

        if (this.showMenu) {
          this.loadMenu();
        } else {
          this.items = [];
        }
      }
    );
  }

  ngOnDestroy(): void {
    this.loginSubscription.unsubscribe();
  }

  public registerToggle(menuItem: MenuToggleComponent): void {
    this.menuToggleDirectorService.registerAsSubToggle(menuItem);
  }

  private loadMenu() {
    if (this.authenticationService.adminOnly) {
      this.items = [this.menuService.getAdminMenu(true, this.config.externalUsersEnabled)];
    } else {
      const menuLoadData: MenuLoadDataObs = {
        permissionsLoaded: this.authorizationService.waitFormPermissionsLoaded$,
        isAdmin: this.authorizationService.getAdministrationStatus(),
        systemParameters: this.systemParameterService.getParameters(),
        systemTypes: this.systemTypeService.waitAndGetSystemTypes$
      };
      forkJoin(menuLoadData)
        .pipe(catchError((error) => of(error)))
        .subscribe(
          (resp: MenuLoadData) => {
            resp.nlqEnabled = this.config.nlqEnabled;
            resp.externalUsersEnabled = this.config.externalUsersEnabled;
            this.items = this.menuService.getMenuItems(resp);
          },
          (error) => this.logger.error(error)
        );
    }
  }
}
