import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Menu } from '@app/menu/shared/menu.model';
import { MenuToggleComponent } from '@app/menu/menu-toggle/menu-toggle.component';

@Component({
  selector: 'acs-menu-item',
  templateUrl: './menu-item.component.html'
})
export class MenuItemComponent {
  @Input() item: Menu;
  @Output() itemChange = new EventEmitter<Menu>();
  @Output() menuChanged = new EventEmitter<MenuToggleComponent>();

  public registerElement: MenuToggleComponent;

  constructor() {}

  public onSubMenuToggled(menuToggle: MenuToggleComponent): void {
    this.menuChanged.emit(menuToggle);
  }

  public registerToggle(menuToggle: MenuToggleComponent): void {
    this.registerElement = menuToggle;
  }
}
