import { Injectable } from '@angular/core';
import { LocalStorageRefService } from '@app/shared/services/browser/local-storage-ref.service';

@Injectable({ providedIn: 'root' })
export class DebugComponentService {
  public static service: DebugComponentService = undefined;

  private componentArr = [];

  constructor(localStorageRef: LocalStorageRefService) {
    if (localStorageRef.getGenericStorageValue('clistdebug')) {
      DebugComponentService.service = this;

      this.setWindowFunction();
    }
  }

  public addComponent(componentInstance: any): void {
    this.componentArr.push(componentInstance);
  }

  public removeComponent(componentInstance: any): void {
    const matchingIndex = this.componentArr.findIndex((x) => x === componentInstance);
    if (matchingIndex > -1) {
      this.componentArr.splice(matchingIndex, 1);
    }
  }

  private setWindowFunction(): void {
    window['clist'] = () => this.componentArr;
  }
}
