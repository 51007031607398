<mat-toolbar [ngClass]="menuCollapsed ? 'justify-content-center':'justify-content-between p-2'">
  <img src="../../../favicon.ico" class="app-icon" />
  <span *ngIf="!menuCollapsed">AUJS</span>
</mat-toolbar>
<acs-menu *ngIf="userLoggedIn; else emptyMenu" class="aujs-menu-directive flex-fill d-flex flex-column"></acs-menu>
<ng-template #emptyMenu>
  <div class="flex-fill d-flex flex-column"></div>
</ng-template>

<button mat-button color="accent" (click)="toggleMenuCollapse()" class="collapse-btn" [ngClass]="menuCollapsed ? 'justify-content-end' : 'justify-content-start'">
  <fa-icon [icon]="collapsedStateIcon" class="fa fa-lg collapse-icon pr-2"></fa-icon>
  <span *ngIf="!menuCollapsed">{{'acs-menu.collapse.label' | translate}}</span>
</button>

