import { Injectable } from '@angular/core';
import { MenuToggleComponent } from '@app/menu/menu-toggle/menu-toggle.component';

@Injectable({
  providedIn: 'root'
})
export class MenuToggleDirectorService {
  private childScopes: Array<MenuToggleComponent> = [];

  constructor() {}

  public registerAsSubToggle(menuToggle: MenuToggleComponent): void {
    if (this.childScopes.indexOf(menuToggle) === -1) {
      if (this.childScopes.length > 0) {
        this.childScopes.forEach((child) => {
          child.collapse();
        });
      }
      this.childScopes.push(menuToggle);
    } else {
      this.childScopes.splice(this.childScopes.indexOf(menuToggle), 1);
    }
  }
}
